import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { withAuth } from "../hocs/withAuth";
import { useAuth } from "../hooks/useAuth";

function Header(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    try {
      localStorage.clear();
      navigate("/login");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{ zIndex: "1", position: "relative" }}>
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {isAuth && (
              <Button color="inherit" component={Link} to="/">
                Licenses
              </Button>
            )}
            {isAuth && permissions?.editUserInfo && (
              <Button color="inherit" component={Link} to="/user-management">
                Users
              </Button>
            )}
            {isAuth && permissions?.editProduct && (
              <Button color="inherit" component={Link} to="/product-management">
                Products
              </Button>
            )}
            {isAuth && (
              <Button color="inherit" component={Link} to={`/profile`}>
                Profile
              </Button>
            )}
          </Box>
          {isAuth ? (
            <Button color="inherit" onClick={logout}>
              Log Out
            </Button>
          ) : (
            <Button color="inherit" component={Link} to="/login">
              Log In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default withAuth(Header);
