import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const licenseAPI = {
  download,
  create,
  getAll,
  getByCreator,
  disable,
  getCompany,
  updateCompany,
  getByCompanyId
};

async function getAll() {
  try {
    return await $api.get(`/License`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByCreator() {
  try {
    return await $api.get(`/license/getByCreator`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function download(id) {
  try {
    return await $api.get(`/license/download?id=${id}`, {
      responseType: "arraybuffer",
    });
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function create(data) {
  try {
    return await $api.post("/license/create", data);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function disable(id) {
  try {
    return await $api.get(`/license/disable?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getCompany(id) {
  try {
    return await $api.get(`/license/getCompanyById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function updateCompany(info) {
  try {
    return await $api.put(`/license/updateCompany`, info);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getByCompanyId(id) {
  try {
    return await $api.get(`/license/getByCompanyId?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
