import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";

function AddProductForm(props) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Grid item xs={6}>
      <Typography variant="h6" margin="40px 0 20px 0">
        Add New Product:
      </Typography>
      <form
        onSubmit={handleSubmit((data) => {
          props.onSubmit(data);
          reset({ name: "", description: "" });
        })}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.name?.message)}
                  fullWidth={true}
                  type="text"
                  label="Product name"
                  variant="outlined"
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth={true}
                  rows={6}
                  placeholder="Product description"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Button
                style={{ width: "91px" }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isLoading}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default AddProductForm;
