import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import UsersTable from "../components/UsersTable";
import { userAPI } from "../services/api/userAPI";
import ErrorNotification from "../components/ErrorNotification";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/material";
import { setUsers } from "../store/slices/usersSlise";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import { withAuth } from "../hocs/withAuth";

function UserManagement(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const { isAuth, user } = useAuth();
  const { users } = useSelector((state) => state.users);

  const clearErrors = () => {
    if (error != null) {
      setError(null);
    }
  }

  const saveChanges = async (id) => {
    setIsLoading(true);
    clearErrors();
    const user = users.find((element) => element.id === id);
    if (user) {
      let response = await userAPI.update(user);
      if (response.status === 200) {
        console.log(`User ${id} updated`);
      } else {
        let message = response.data.message;
        setError({
          code: response.status,
          message: message ? message : response.statusText,
        });
      }
    }
    setIsLoading(false);
    await loadUsers();
  };

  const loadUsers = async () => {
    setIsLoading(true);
    let response = await userAPI.getAll();
    if (response.status === 200) {
      const usersExeptSuperAdmin = response.data.filter((u) => {
        return !u.superAdmin;
      });
      let users = usersExeptSuperAdmin.map((obj) => ({
        ...obj,
        isEdited: false,
      }));
      dispatch(setUsers(users.reverse()));
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeUser = (id, action, data) => {
    dispatch(action({ id, data }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(async () => {
    if (!isAuth || !permissions?.editUserInfo) return;
    clearErrors();
    await loadUsers();
  }, [isAuth, permissions]);

  if(!isAuth){
    return <Navigate to="/login" />
  }

  if(permissions && !permissions?.editUserInfo){
    return <Navigate to="/forbidden" />
  }

  return (
    <Container maxWidth="xl">
      <title>User management</title>
      <div>
        <UsersTable
          currentUserId={user?.id}
          users={users}
          page={page}
          saveChanges={saveChanges}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangeUser={handleChangeUser}
        />
      </div>
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{ margin: "60px 0 20px 0", color: "#EB3941" }}
        />
      )}
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(UserManagement);
