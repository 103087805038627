import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorNotification from "../components/ErrorNotification";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../hooks/useAuth";
import { userAPI } from "../services/api/userAPI";

function Forbidden() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();

  const updateRights = async () => {
    setIsLoading(true);
    let response = await userAPI.get(user.id);
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify({...response.data, token: user.token}));
      navigate("/");
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Container maxWidth="xl">
        <title>Forbidden</title>
        <h1>Action is forbidden</h1>
        <Typography variant="h5">
          You no longer have the rights to this operation.
        </Typography>
        <Typography variant="h5">
          Сontact the support service for more information.
        </Typography>
        <Box pt={3}>
          <Button variant="contained" onClick={updateRights}>
            Ok
          </Button>
        </Box>
      </Container>
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{ margin: "60px 0 20px 0", color: "#EB3941" }}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
}
export default Forbidden;
