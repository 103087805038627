import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import ActionButton from "./ActionButton";
import SearchBar from "./SearchBar";
import { Link as ButtonLink } from "react-router-dom";
import DateWithExpiration from "./DateWithExpiration/DateWithExpiration";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#BDBDBD",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const searchTypes = {
  all: { name: "all", display: "All"  },
  byCompany: { name: "company", display: "Company"},
  byScannerModel: { name: "scannerModel", display: "Scanner model" },
  byScannerSerialNumber: { name: "scannerSerialNumber", display: "Scanner number" },
  byProduct: { name: "product", display: "Product" },
  byCreator: { name: "creator", display: "Creator" },
};

function LicenseTable(props) {
  const [page, setPage] = useState(localStorage.getItem("page") ? parseInt(localStorage.getItem("page")) : 0);
  const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem("rowsPerPage") ? parseInt(localStorage.getItem("rowsPerPage")) : 25);
  const [order, setOrder] = useState(localStorage.getItem("order") ?? "desc");
  const [orderBy, setOrderBy] = useState(localStorage.getItem("orderBy") ?? "creationTime");
  const [rows, setRows] = useState(props.collection);
  const [searched, setSearched] = useState(localStorage.getItem("searchText") ?? "");
  const [searchColumn, setSearchColumn] = useState(localStorage.getItem("searchColumn") ?? searchTypes.all.name);

  useEffect(() => { requestSearch(searched) }, [searchColumn]);

  useEffect(() => { 
    Boolean(searched) ? requestSearch(searched) : updateRows(props.collection);
  }, [props.collection] );

  const updateRows = (data) => {
    setRows(data);
    props.onChanged(data);
  };

  const updatePageNumber = (value) => {
    localStorage.setItem("page", value);
    setPage(value);
  }

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    updatePageNumber(0);

    if (!searchedVal) {
      localStorage.removeItem("searchText");
      updateRows(props.collection);
      return;
    }

    localStorage.setItem("searchText", searchedVal);

    if (searchColumn === searchTypes.all.name) {
      const filteredRows = props.collection.filter((row) => {
        return [
          row.scannerModel,
          row.scannerSerialNumber,
          row.company?.name,
          row.creator?.name,
          row.creator?.surname,
          ...row.products,
        ].some((x) => x?.toLowerCase().includes(searchedVal.toLowerCase()));
      });
      updateRows(filteredRows);
    }
    if (searchColumn === searchTypes.byScannerModel.name) {
      const filteredRows = props.collection.filter((row) => {
        return row.scannerModel?.toLowerCase().includes(searchedVal.toLowerCase());
      });
      updateRows(filteredRows);
    }
    if (searchColumn === searchTypes.byScannerSerialNumber.name) {
      const filteredRows = props.collection.filter((row) => {
        return row.scannerSerialNumber?.toLowerCase().includes(searchedVal.toLowerCase());
      });
      updateRows(filteredRows);
    }
    if (searchColumn === searchTypes.byCompany.name) {
      const filteredRows = props.collection.filter((row) => {
        return row.company?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      });
      updateRows(filteredRows);
    }
    if (searchColumn === searchTypes.byCreator.name) {
      const filteredRows = props.collection.filter((row) => {
        return [row.creator?.name, row.creator?.surname].some(x => x.toLowerCase().includes(searchedVal.toLowerCase()));
      });
      updateRows(filteredRows);
    }
    if (searchColumn === searchTypes.byProduct.name) {
      const filteredRows = props.collection.filter((row) => {
        return row.products?.some(x => x?.toLowerCase().includes(searchedVal.toLowerCase()));
      });
      updateRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    requestSearch("");
  };

  const handleChangePage = (event, newPage) => {
    updatePageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("rowsPerPage", parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    updatePageNumber(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    localStorage.setItem("order", isAsc ? "desc" : "asc")
    setOrder(isAsc ? "desc" : "asc");

    localStorage.setItem("orderBy", property);
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const onSearchColumnChanged = (value) => {
    localStorage.setItem("searchColumn", value);
    setSearchColumn(value);
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{ width: "100%", height: "49px" }}
            value={searchColumn}
            onChange={(e) => { onSearchColumnChanged(e.target.value) }}
          >
            {Object.values(searchTypes).map((el, index) => (
              <MenuItem key={index} value={el.name}>{el.display}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700, margin: "5px 0px" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Company</StyledTableCell>
              <StyledTableCell align="center">Contact person</StyledTableCell>
              <StyledTableCell align="center">Scanner model</StyledTableCell>
              <StyledTableCell align="center">Serial #</StyledTableCell>
              <StyledTableCell align="center">Products</StyledTableCell>
              <StyledTableCell
                key="creationTime"
                sortDirection={orderBy === "creationTime" ? order : false}
              >
                <Tooltip title="DD.MM.YYYY" placement="bottom-start">
                  <TableSortLabel
                    active={orderBy === "creationTime"}
                    direction={orderBy === "creationTime" ? order : "asc"}
                    onClick={createSortHandler("creationTime")}
                  >
                    Issue date
                    {orderBy === "creationTime" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </Tooltip>  
              </StyledTableCell>
              <StyledTableCell
                key="validTill"
                sortDirection={orderBy === "validTill" ? order : false}
              >
                <Tooltip title="DD.MM.YYYY" placement="bottom-start">
                  <TableSortLabel
                    active={orderBy === "validTill"}
                    direction={orderBy === "validTill" ? order : "asc"}
                    onClick={createSortHandler("validTill")}
                  >
                    Valid till
                    {orderBy === "validTill" ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center">Issued by</StyledTableCell>
              <StyledTableCell align="center">Download key</StyledTableCell>
              {props.canAddLicense && (
                <StyledTableCell align="center"></StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(0, rows.length)
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={index}>
                  <StyledTableCell component="th">
                    <Box textAlign="center">
                      <Typography style={{textTransform: "uppercase", fontSize: "14px"}}>
                        {props.canEditInfo && item.company ? (
                          <Button color="inherit" component={ButtonLink} to={`/companyInfo/${item.company?.id}`}>
                            <Link href="#" color="inherit">
                              {item.company.name || "<NoName>"}
                            </Link>
                          </Button>
                        ) : (
                          !item.company ? "<Unknown>" : item.company.name || "<NoName>"
                        )}
                      </Typography>
                      <Typography>
                        {item.company?.link && (
                          <Link href={item.company?.link} target="_blank">
                            Link to CRM
                          </Link>
                        )}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      <div>{item.company?.contactPerson}</div>
                      <div>{item.company?.contactPhone}</div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.scannerModel}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.scannerSerialNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      {item.products.map((product, index) => (
                        <div key={index}>{product}</div>
                      ))}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {/* {new Date(item.creationTime).toLocaleDateString('ru-RU', { day: 'numeric', month: 'short', year: 'numeric' })} */}
                    {new Date(item.creationTime).toLocaleDateString("ru-RU")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <DateWithExpiration date={new Date(item.validTill.replace("Z", ""))}/>
                    {/* <DateWithExpiration date={new Date("2022-09-13T23:59:59.000")}/> */}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip
                      title={item.creator?.description || ''}
                      placement="bottom-start"
                    >
                      <Box>{`${item.creator?.name || ""} ${item.creator?.surname || ""}`}</Box>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box textAlign="center">
                      {item.disabled ? (
                        "Disabled"
                      ) : (
                        <Button
                          disabled={props.isLoading || !props.canDownload}
                          variant="contained"
                          size="small"
                          onClick={() => props.onDownload(item.id)}
                        >
                          Download
                        </Button>
                      )}
                    </Box>
                  </StyledTableCell>
                  {props.canAddLicense && (
                    <StyledTableCell>
                      <Box textAlign="center">
                        {!item.disabled && (
                          <ActionButton
                            title="Delete license?"
                            contentText="If you delete the license, you couldn't restore it, but it will still remain in the list as disabled"
                            confirmText="Delete"
                            rejectText="Cancel"
                            disabled={props.isLoading}
                            variant="outlined"
                            size="small"
                            onClick={() => props.onDisable(item.id)}
                          >
                            Delete
                          </ActionButton>
                        )}
                      </Box>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default LicenseTable;