import { Grid, Typography, Link } from "@mui/material";
import React from "react";

function ProductCard(props) {
  return (
    <Grid item xs={6} key={props.product.id}>
      <Typography
        variant="h12"
        marginRight="10px"
        style={{ fontWeight: "bold" }}
      >
        {props.product.name || "<NoName>"}
      </Typography>
      {!props.disabled && (
        <Link underline="hover" component="button" onClick={props.onEdit}>
          edit
        </Link>
      )}
      <Typography
        variant="caption"
        display="block"
        style={{ whiteSpace: "pre-line" }}
      >
        {props.product.description}
      </Typography>
    </Grid>
  );
}

export default ProductCard;
