import s from "./DateWithExpiration.module.css";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

function daysToMilliseconds(days) {
  //           hour  min  sec  ms
  return days * 24 * 60 * 60 * 1000;
}

const DateWithExpiration = ({ date }) => {
  const [difference, setDifference] = useState(0);
  const [tillEndDay, setTillEndDay] = useState(0);

  const thirtyDays = daysToMilliseconds(30);
  const oneDay = daysToMilliseconds(1);

  useEffect(() => {
    let today = new Date();
    let diff = date.getTime() - today.getTime();
    setDifference(diff);

    let adjustedDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    let endDay = adjustedDate - today + 1000;
    setTillEndDay(endDay);
  }, [date]);

  return (
    <div className={s.container}>
      {difference <= 0 ? (
        <ExpiredIndicator />
      ) : difference > 0 && difference <= tillEndDay ? (
        <ExpiresTodayIndicator date={date.toLocaleString("ru-RU")} />
      ) : (
        difference <= thirtyDays && (
          <ExpiresSoonIndicator
            days={Math.ceil((difference - tillEndDay) / oneDay)}
            date={date.toLocaleString("ru-RU")}
          />
        )
      )}
      <div className={s.date + " " + (difference < 0 && s.expired)}>
        {new Date(date).toLocaleDateString("ru-RU")}
      </div>
    </div>
  );
};

export default DateWithExpiration;

const ExpiredIndicator = () => {
  return (
    <Tooltip title={`License has expired`} placement="top-start">
      <ErrorIcon color="error" sx={{ margin: "0px 5px 0px 0px" }} />
    </Tooltip>
  );
};

const ExpiresTodayIndicator = ({ date }) => {
  return (
    <Tooltip title={`License expires today ${date}`} placement="top-start">
      <NewReleasesOutlinedIcon
        color="error"
        sx={{ margin: "0px 5px 0px 0px" }}
      />
    </Tooltip>
  );
};

const ExpiresSoonIndicator = ({ days, date }) => {
  return (
    <Tooltip
      title={`License expires in ${days === 0 ? 1 : days} day(s) ${date}`}
      placement="top-start"
    >
      <NewReleasesOutlinedIcon
        color="error"
        sx={{ margin: "0px 5px 0px 0px" }}
      />
    </Tooltip>
  );
};
