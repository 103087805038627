import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorNotification from "../components/ErrorNotification";
import { licenseAPI } from "../services/api/licenseAPI";
import CreateLicenseForm from "../components/CreateLicenseForm";
import { productAPI } from "../services/api/productAPI";
import { companyAPI } from "../services/api/companyAPI";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import { deviceAPI } from "../services/api/deviceAPI";
import { withAuth } from "../hocs/withAuth";
import { useSelector } from "react-redux";

function CreateLicensePage(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [models, setModels] = useState([]);
  const [scannerTypes, setScannerTypes] = useState([]);
  const [scannerNumbers, setScannerNumbers] = useState([]);
  const navigate = useNavigate();
  const { isAuth } = useAuth();
  const [isScannerKeyRequered, setIsScannerKeyRequered] = useState(false);
  const [scannerKey, setScannerKey] = useState(null);

  useEffect(async () => {
    if (!isAuth) return;
    await loadCompanies();
    await loadProducts();
    await loadScannerTypes();
  }, [isAuth]);

  const resetScannerKey = () => {
    setIsScannerKeyRequered(false);
    setScannerKey(null);
  }

  const updateModels = (models) => {
    setModels(models);
    setScannerNumbers([]);
  }

  const loadCompanies = async () => {
    setIsLoading(true);
    setError(null);
    let response = await companyAPI.getAll();
    if (response.status === 200) {
      const companiesResult = [...new Set(response.data.map((item) => item))];
      setCompanies(companiesResult);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const loadScannerTypes = async () => {
    setIsLoading(true);
    setError(null);
    let response = await deviceAPI.get();
    if (response.status === 200) {
      const typesResult = [...new Set(response.data.filter(item => item.type != null).map(item => item.type))];
      setScannerTypes(typesResult);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const loadProducts = async () => {
    setIsLoading(true);
    setError(null);
    let response = await productAPI.getAll();
    if (response.status === 200) {
      const productsResult = [...new Set(response.data.map((item) => item))];
      setProducts(productsResult);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const loadDeviceByType = async (type) => {
    setIsLoading(true);
    setError(null);
    let response = await deviceAPI.get({type});
    if (response.status === 200) {
      const modelsResult = [
        ...new Set(response.data.map((item) => item.model)),
      ];
      updateModels(modelsResult);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const onTypeChange = async (data) => {
    resetScannerKey();
    if (data.type === null) {
      updateModels([]);
      return;
    }
    await loadDeviceByType(data.type);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError(null);
    const deviceResponse = await deviceAPI.get({
      model: data.scannerModel,
      serialNumber: data.scannerSerialNumber,
    });
    handleResponse(deviceResponse, async () => {
      if (isScannerKeyRequered || deviceResponse.data[0].hardwareId !== data.scannerKey){
        const hardwareIdResponse = await deviceAPI.setHardwareId(
          deviceResponse.data[0].id,
          data.scannerKey
        );
        handleResponse(hardwareIdResponse, async () => {
          const createResponse = await licenseAPI.create(data);
          handleResponse(createResponse, () => {
            navigate("/");
            setIsLoading(false);
          }, () => setIsLoading(false));
        }, () => setIsLoading(false));
      } else {
        const createResponse = await licenseAPI.create(data);
          handleResponse(createResponse, () => {
            navigate("/");
            setIsLoading(false);
          }, () => setIsLoading(false));
      }
    }, () => setIsLoading(false));
  };

  const handleResponse = (response, action, errorAction) => {
    if (response.status === 200) {
      action();
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
      if(errorAction){
        errorAction();
      }
    }
  }

  const loadDeviceByModel = async (model) => {
    setIsLoading(true);
    setError(null);
    let response = await deviceAPI.get({model});
    if (response.status === 200) {
      let numbers = [
        ...new Set(response.data.map((item) => item.serialNumber)),
      ].sort();
      setScannerNumbers(numbers);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const onModelChange = async (data) => {
    resetScannerKey();
    if (data.scannerModel === null) {
      setScannerNumbers([]);
      return;
    }
    await loadDeviceByModel(data.scannerModel);
  };

  const loadScannerKey = async (model, serialNumber) => {
    setIsLoading(true);
    setError(null);
    let response = await deviceAPI.get({model, serialNumber});
    if (response.status === 200) {
      const key = response.data[0].hardwareId;
      setIsScannerKeyRequered(!Boolean(key));
      setScannerKey(key);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const onSerialNumberChange = async (data) => {
    data.scannerSerialNumber === null
      ? resetScannerKey()
      : await loadScannerKey(data.scannerModel, data.scannerSerialNumber);
  };

  if(!isAuth) {
    return <Navigate to="/login" />
  }

  if(permissions && !permissions?.addLicense){
    return <Navigate to="/" />
  }

  return (
    <div>
      <title>Create license</title>
      <CreateLicenseForm
        isLoading={isLoading}
        products={products}
        models={models}
        scannerTypes={scannerTypes}
        companies={companies}
        scannerNumbers={scannerNumbers}
        onModelChange={onModelChange}
        onSubmit={onSubmit}
        onTypeChange={onTypeChange}
        onSerialNumberChange={onSerialNumberChange}
        isScannerKeyRequered={isScannerKeyRequered}
        scannerKey={scannerKey}
      />
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{
            margin: "60px 0 20px 0",
            color: "#EB3941",
            textAlign: "center",
          }}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
}

export default withAuth(CreateLicensePage);
