import { Grid, styled, TextField, Typography } from "@mui/material";
import React from "react";

const StyledTextField = styled(TextField)({
  '*.Mui-disabled': {
    WebkitTextFillColor: '#212121'
  }})

function ProfileInfo(props) {
  var {name, surname, email, description} = props;

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Profile
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth={true}
                type="text"
                label="Name"
                variant="outlined"
                value={name}
                disabled
                InputLabelProps={{style: {WebkitTextFillColor: '#666666'}}}
              />
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                fullWidth={true}
                type="text"
                label="Surname"
                variant="outlined"
                value={surname}
                disabled
                InputLabelProps={{style: {WebkitTextFillColor: '#666666'}}}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            fullWidth={true}
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            disabled
            InputLabelProps={{style: {WebkitTextFillColor: '#666666'}}}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            multiline
            fullWidth={true}
            rows={3}
            label="Сompany and position details"
            variant="outlined"
            value={description}
            disabled
            InputLabelProps={{style: {WebkitTextFillColor: '#666666'}}}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileInfo;
