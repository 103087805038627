import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const userAPI = {
  get,
  getAll,
  update,
  changePassword,
};

async function get(id) {
  try {
    return await $api.get(`/users/getById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getAll() {
  try {
    return await $api.get(`/Users`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function update(user) {
  try {
    return await $api.put(`/users/updateUser`, user);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function changePassword(currentPassword, newPassword) {
  try {
    return await $api.put(`/users/updatePassword`, {
      currentPassword,
      newPassword,
    });
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
