import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const deviceAPI = {
  get,
  setHardwareId
};

async function get(params) {
  try {
    return await $api.get(`/device/get`, {params});
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function setHardwareId(deviceId, hardwareId) {
  try {
    return await $api.put(`/device/setHardwareId`, {
      key: deviceId,
      hardwareId: hardwareId,
    });
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
