import { Container } from "@mui/material";
import { Navigate } from "react-router-dom";
import ErrorNotification from "../components/ErrorNotification";
import LicenseTable from "../components/LicenseTable";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { licenseAPI } from "../services/api/licenseAPI";
import { setLicenses, disableLicense } from "../store/slices/licensesSlise";
import { useAuth } from "../hooks/useAuth";
import { withAuth } from "../hocs/withAuth";
import ActionBox from "../components/ActionBox";

function HomePage(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { isAuth } = useAuth();
  const { licenses } = useSelector((state) => state.licenses);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataToSave, setDataToSave] = useState(licenses);
  const dispatch = useDispatch();

  const clearErrors = () => {
    if (error != null) {
      setError(null);
    }
  }

  useEffect(async () => {
    if (!isAuth) return;
    clearErrors();
    await loadLicenses();
  }, [isAuth, permissions]);

  const loadLicenses = async () => {
    setIsLoading(true);
    let response = permissions?.seeAllCustomers
      ? await licenseAPI.getAll()
      : await licenseAPI.getByCreator();
    if (response.status === 200) {
      dispatch(setLicenses(response.data.reverse()));
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const handleDisableLicense = async (id) => {
    setIsLoading(true);
    clearErrors();
    let response = await licenseAPI.disable(id);
    if (response.status === 200) {
      dispatch(disableLicense(id));
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const download = async (id) => {
    setIsLoading(true);
    clearErrors();
    let response = await licenseAPI.download(id);
    if (response.status === 200) {
      const contentDisposition = response.headers["content-disposition"];
      const content = contentDisposition.split(";");
      const index = content.findIndex((el) => el.includes(" filename="));
      if (index !== -1) {
        const filename = content[index].replace(" filename=", "");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
      }
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  if(!isAuth){
    return <Navigate to="/login" />
  }

  return (
    <Container maxWidth="xl">
      <title>License management</title>
      <div>
        <h1>Software licensing portal</h1>
        <ActionBox permissions={permissions} margin="0px 0px 20px 20px" data={dataToSave}/>
        <LicenseTable
          isLoading={isLoading}
          collection={licenses}
          onDisable={handleDisableLicense}
          onDownload={download}
          onChanged={(data) => setDataToSave(data)}
          canAddLicense={permissions?.addLicense}
          canEditInfo={permissions?.editCompanyInfo}
          canDownload={permissions?.downloadLicense}
        />
        <ActionBox permissions={permissions} margin="20px 0px 20px 20px" data={dataToSave}/>
      </div>
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{ margin: "60px 0 20px 0", color: "#EB3941" }}
        />
      )}
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(HomePage);

