import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

function ChangePasswordForm(props) {
  const schema = yup.object().shape({
    currentPassword: yup.string().min(4).required(),
    newPassword: yup
      .string()
      .min(4)
      .required()
      .notOneOf(
        [yup.ref("currentPassword"), null],
        "Password must be different from the current one"
      )
      .notOneOf(
        [props.login, null],
        "Password must be different from the login"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Change password
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              name="currentPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.currentPassword?.message)}
                  type="password"
                  autoComplete="off"
                  fullWidth={true}
                  label="Current password"
                  variant="outlined"
                  helperText={errors.currentPassword?.message}
                />
              )}
            />
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={Boolean(errors.newPassword?.message)}
                    type="password"
                    autoComplete="off"
                    fullWidth={true}
                    label="New password"
                    variant="outlined"
                    helperText={errors.newPassword?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={Boolean(errors.confirmPassword?.message)}
                    type="password"
                    autoComplete="off"
                    fullWidth={true}
                    label="Confirm password"
                    variant="outlined"
                    helperText={errors.confirmPassword?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  style={{ width: "91px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={props.isLoading}
                >
                  Change
                </Button>
              </Grid>
              <Grid item>
                {props.responseError && (
                  <Typography style={{ lineHeight: "37px", color: "#EB3941" }}>
                    {props.responseError.message}
                  </Typography>
                )}
                {props.isUpdated && (
                  <Typography style={{ lineHeight: "37px", color: "#A6A6A6" }}>
                    Password is updated
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
