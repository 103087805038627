import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import NotFound from "../pages/NotFound";
import RegistrationPage from "../pages/RegistrationPage";
import ProductManagement from "../pages/ProductManagement";
import UserManagement from "../pages/UserManagement";
import RegistrationConfirmation from "../components/RegistrationConfirmation";
import CreateLicensePage from "../pages/CreateLicensePage";
import CompanyInfoPage from "../pages/CompanyInfoPage";
import ProfilePage from "../pages/ProfilePage";
import PasswordRecoveryPage from "../pages/PasswordRecoveryPage";
import ResetPasswordConfirmation from "../components/ResetPasswordConfirmation";
import Forbidden from "../pages/Forbidden";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/register-confirmed" element={<RegistrationConfirmation />} />
      <Route path="/not-found-404" element={<NotFound />} />
      <Route path="/product-management" element={<ProductManagement />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/create-license" element={<CreateLicensePage />} />
      <Route path="/companyInfo/:id" element={<CompanyInfoPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/forgot-password" element={<PasswordRecoveryPage />} />
      <Route path="/reset-password-confirmation" element={<ResetPasswordConfirmation/>} />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
