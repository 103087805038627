import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

function EditProductForm(props) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(async () => {
    if (props.preloads) {
      reset({ ...props.preloads });
    }
  }, [props.preloads]);

  return (
    <Grid item xs={6}>
      <Typography variant="h6" margin="40px 0 20px 0">
        Edit Product:
      </Typography>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.name?.message)}
                  fullWidth={true}
                  type="text"
                  label="Product name"
                  variant="outlined"
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth={true}
                  rows={6}
                  placeholder="Product description"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  disabled={props.disabled}
                  style={{ width: "91px" }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={props.disabled}
                  style={{ width: "91px" }}
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    handleSubmit(props.onSubmit(null));
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default EditProductForm;
