import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Grid, Typography, TextField, Button } from "@mui/material";

function CompanyForm(props) {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(async () => {
    if (props.preloads) {
      reset({ ...props.preloads });
    }
  }, [props.preloads]);

  return (
    <Grid item xs={6}>
      <Typography variant="h6" margin="40px 0 20px 0">
        Edit company:
      </Typography>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errors.name?.message)}
                  fullWidth={true}
                  type="text"
                  label="Company name"
                  variant="outlined"
                  helperText={errors.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth={true}
                  type="text"
                  label="Company address"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="link"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  fullWidth={true}
                  type="text"
                  label="Link to CRM"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="contactPerson"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      label="Contact person"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="contactPhone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      label="Phone"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="comments"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  fullWidth={true}
                  rows={6}
                  placeholder="Some comments..."
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Button
                style={{ width: "91px" }}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default CompanyForm;
