import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

function ActionButton(props) {
  const {
    onClick,
    variant,
    size,
    disabled,
    children,
    title,
    contentText,
    rejectText,
    confirmText,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = () => {
    setOpen(false);
    onClick();
  };

  return (
    <div>
      <Button
        variant={variant}
        size={size}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {rejectText}
          </Button>
          <Button onClick={handleAction} color="primary">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ActionButton;
