import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";

function LicenseCard(props) {
  return (
    <Grid item xs={6}>
      <Typography
        variant="h12"
        marginRight="10px"
        style={{ fontWeight: "bold", lineHeight: "40px" }}
      >
        {props.scannerModel || "<Unknown>"} #{props.scannerSerialNumber || "????"}
      </Typography>
      {props.canDownload &&
        (props.disabled ? (
          <IconButton disabled>
            <FileDownloadOffIcon />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            aria-label="donwnload"
            onClick={() => props.onDownload(props.id)}
          >
            <DownloadIcon />
          </IconButton>
        ))}
      <Typography
        variant="caption"
        display="block"
        style={{ whiteSpace: "pre-line" }}
      >
        by {props.creator?.name || "<Unknown>"} {props.creator?.surname || "<Unknown>"}
      </Typography>
    </Grid>
  );
}

export default LicenseCard;
