import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  Tooltip,
  Input,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import {
  toggleActivate,
  toggleAddLicense,
  toggleDownloadLicense,
  toggleSeeAllCustomers,
  toggleEditCompany,
  toggleEditUser,
  toggleEditProduct,
  changeName,
  changeSurname,
  changeEmail,
  toggleEditMode,
  updateUser,
} from "../store/slices/usersSlise";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#BDBDBD",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function UsersTable(props) {
  const [previous, setPrevious] = useState(null);

  const handleActivateProperty = (user) => {
    if (user.isActive) {
      // сбрасываем все включенные разрешения, если пользователь был активен и имел какие-либо разрешения,
      // так как неактивный пользователь не может ничего
      if (user.permissions?.downloadLicense) {
        props.handleChangeUser(user.id, toggleDownloadLicense);
      }
      if (user.permissions?.addLicense) {
        props.handleChangeUser(user.id, toggleAddLicense);
      }
      if (user.permissions?.seeAllCustomers) {
        props.handleChangeUser(user.id, toggleSeeAllCustomers);
      }
      if (user.permissions?.editCompanyInfo) {
        props.handleChangeUser(user.id, toggleEditCompany);
      }
      if (user.permissions?.editUserInfo) {
        props.handleChangeUser(user.id, toggleEditUser);
      }
      if (user.permissions?.editProduct) {
        props.handleChangeUser(user.id, toggleEditProduct);
      }
    }
    props.handleChangeUser(user.id, toggleActivate);
  };

  const handleNameProperty = (user, name) => {
    props.handleChangeUser(user.id, changeName, name);
  };

  const handleSurnameProperty = (user, surname) => {
    props.handleChangeUser(user.id, changeSurname, surname);
  };

  const handleEmailProperty = (user, email) => {
    props.handleChangeUser(user.id, changeEmail, email);
  };

  const switchEditMode = (user) => {
    props.handleChangeUser(user.id, toggleEditMode);
    setPrevious(user);
  };

  const revert = (user) => {
    props.handleChangeUser(user.id, toggleEditMode);
    props.handleChangeUser(user.id, updateUser, previous);
  };

  return (
    <div>
      <h1>User Management</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="center">User name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Active</StyledTableCell>
              <StyledTableCell align="center">Download license</StyledTableCell>
              <StyledTableCell align="center">Add new license</StyledTableCell>
              <StyledTableCell align="center">
                See all customers
              </StyledTableCell>
              <StyledTableCell align="center">
                Edit company info
              </StyledTableCell>
              <StyledTableCell align="center">User management</StyledTableCell>
              <StyledTableCell align="center">Product management</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users
              .slice(
                props.page * props.rowsPerPage,
                props.page * props.rowsPerPage + props.rowsPerPage
              )
              .map((user, index) => (
                <TableRow key={index}>
                  <StyledTableCell align="center">
                    {user.id !== props.currentUserId &&
                      (user.isEdited ? (
                        <>
                          <IconButton
                            aria-label="done"
                            onClick={() => props.saveChanges(user.id)}
                          >
                            <DoneAllIcon />
                          </IconButton>
                          <IconButton
                            aria-label="revert"
                            onClick={() => revert(user)}
                          >
                            <DoNotDisturbIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => switchEditMode(user)}
                        >
                          <EditIcon />
                        </IconButton>
                      ))}
                  </StyledTableCell>
                  <StyledTableCell align="center" scope="user">
                    {user.isEdited ? (
                      <Box justifyContent="center" alignItems="center">
                        <Grid item>
                          <Input
                            value={user.name}
                            name="name"
                            onChange={(e) =>
                              handleNameProperty(user, e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            value={user.surname}
                            name="surname"
                            onChange={(e) =>
                              handleSurnameProperty(user, e.target.value)
                            }
                          />
                        </Grid>
                      </Box>
                    ) : user.description ? (
                      <Tooltip
                        title={user.description}
                        placement="bottom-start"
                      >
                        <Box>{`${user.name} ${user.surname}`}</Box>
                      </Tooltip>
                    ) : (
                      `${user.name} ${user.surname}`
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {user.isEdited ? (
                      <Input
                        value={user.email}
                        name="email"
                        onChange={(e) =>
                          handleEmailProperty(user, e.target.value)
                        }
                      />
                    ) : (
                      user.email
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId || !user.isEdited
                      }
                      color="primary"
                      checked={user.isActive}
                      onChange={() => handleActivateProperty(user)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.downloadLicense}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleDownloadLicense)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.addLicense}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleAddLicense)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.seeAllCustomers}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleSeeAllCustomers)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.editCompanyInfo}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleEditCompany)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.editUserInfo}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleEditUser)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Checkbox
                      disabled={
                        user.id === props.currentUserId ||
                        !user.isEdited ||
                        !user.isActive
                      }
                      color="primary"
                      checked={user.permissions?.editProduct}
                      onChange={() =>
                        props.handleChangeUser(user.id, toggleEditProduct)
                      }
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={props.users.length}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

export default UsersTable;
