import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  licenses: [],
};

const licensesSlise = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    setLicenses(state, action) {
      state.licenses = action.payload;
    },
    disableLicense(state, action) {
      let licenseIndex = state.licenses.findIndex((obj) => obj.id === action.payload);
      if (licenseIndex === -1) return;
      state.licenses[licenseIndex].disabled = true;
    },
  },
});

export const {
  setLicenses,
  disableLicense
} = licensesSlise.actions;

export default licensesSlise.reducer;
