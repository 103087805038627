import { Container, Grid, List, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { productAPI } from "../services/api/productAPI";
import ErrorNotification from "../components/ErrorNotification";
import EditProductForm from "../components/EditProductForm";
import AddProductForm from "../components/AddProductForm";
import ProductCard from "../components/ProductCard";
import { useAuth } from "../hooks/useAuth";
import Loader from "../components/Loader/Loader";
import { withAuth } from "../hocs/withAuth";
import { useSelector } from "react-redux";

function ProductManagement(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const { isAuth, user } = useAuth();
  const [products, setProducts] = useState([]);
  const [productToEdit, setProductToEdit] = useState(null);

  useEffect(async () => {
    if (!isAuth || !permissions?.editProduct) return;
    await loadProducts();
  }, [isAuth, permissions]);

  const create = async (data) => {
    setIsLoading(true);
    setError(null);
    let response = await productAPI.create(data);
    if (response.status === 200) {
      await loadProducts();
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const switchEditMode = async (id) => {
    if (editMode && id === productToEdit?.id) {
      return;
    }
    setIsLoading(true);
    setError(null);
    let response = await productAPI.get(id);
    if (response.status === 200) {
      setProductToEdit(response.data);
      setEditMode(true);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const loadProducts = async () => {
    setIsLoading(true);
    setError(null);
    let response = await productAPI.getAll();
    if (response.status === 200) {
      setProducts(response.data.reverse());
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const saveChanges = async (data) => {
    if (!data) {
      setEditMode(false);
      return;
    }
    setIsLoading(true);
    setError(null);
    let response = await productAPI.update(data);
    if (response.status === 200) {
      setEditMode(false);
      await loadProducts();
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  if(!isAuth){
    return <Navigate to="/login" />
  }

  if(permissions && !permissions?.editProduct){
    return <Navigate to="/forbidden" />
  }

  return (
    <Container maxWidth="xl">
      <title>Product management</title>
      <h1>Product Management</h1>
      <Grid container spacing={10}>
        {editMode ? (
          <EditProductForm
            preloads={productToEdit}
            onSubmit={saveChanges}
            disabled={isLoading}
          />
        ) : (
          <AddProductForm onSubmit={create} isLoading={isLoading} />
        )}
        <Grid item xs={6}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Available products:
          </Typography>
          <div style={{ overflow: "auto" }}>
            <List>
              <Grid container spacing={2} className={"grid-column"}>
                {products.map((i, index) => (
                  <ProductCard
                    key={index}
                    product={i}
                    onEdit={() => switchEditMode(i.id)}
                    disabled={isLoading}
                  />
                ))}
              </Grid>
            </List>
          </div>
        </Grid>
      </Grid>
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{ margin: "40px 0 0 0", color: "#EB3941" }}
        />
      )}
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(ProductManagement);
