import { Navigate, useNavigate } from "react-router-dom";
import { authAPI } from "../services/api/authAPI";
import { useState } from "react";
import ErrorNotification from "../components/ErrorNotification";
import { useAuth } from "../hooks/useAuth";
import PasswordRecoveryForm from "../components/PasswordRecoveryForm";
import Loader from "../components/Loader/Loader";

function PasswordRecoveryPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuth } = useAuth();

  const onSubmit = async (data) => {
    setIsLoading(true);
    let response = await authAPI.restorePassword(data.email);
    if (response.status === 200) {
      navigate("/reset-password-confirmation");
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  if(isAuth){
    return <Navigate to="/" />
  }

  return (
    <div>
      <title>Reset password</title>
      <PasswordRecoveryForm onSubmit={onSubmit} isLoading={isLoading} />
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{
            margin: "60px 0 20px 0",
            color: "#EB3941",
            textAlign: "center",
          }}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
}

export default PasswordRecoveryPage;
