import { Button } from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const ExcelExportHelper = ({ data }) => {
  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "license_list.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    
    return buf;
  };

  const handleExport = () => {

    let table1 = [
      {
        A: "Company name,\naddress,\ncontact person",
        B: "Comment about company",
        C: "Scanner Model",
        D: "Serial #",
        E: "Products",
        F: "Issue date",
        G: "Valid till",
        H: "Issued by"
      },
    ];

    data.forEach((el) => {

      table1.push({
        A: `${el.company?.name || "<NoName>"}\n${el.company?.address || ""}\n${el.company?.contactPerson || ""}`,
        B: el.company?.comments || "",
        C: el.scannerModel,
        D: el.scannerSerialNumber,
        E: el.products.join("\n"),
        F: el.creationTime ? new Date(el.creationTime) : "NaN",
        G: el.validTill ? new Date(el.validTill) : "NaN",
        H: `${el.creator?.name || ""} ${el.creator?.surname || ""}\n${el.creator?.description || ""}`
      });
    });

    const finalData = [...table1];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "student_report");
    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Company name,\naddress,\ncontact person" ? headerIndexes.push(index) : null
    );

    const totalRecords = data.length;

    const dataInfo = {
      tbodyRange: `A2:H${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
          : null,
      // tFirstColumnRange:
      //   headerIndexes?.length >= 1
      //     ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
      //     : null,
      tLastColumnRange:
        headerIndexes?.length >= 1
          ? `G${headerIndexes[0] + 1}:G${totalRecords + headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
          wrapText: true,
        });

        sheet.column("A").width(40);
        sheet.column("B").width(30);
        sheet.column("C").width(35);
        sheet.column("E").width(30);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(40);

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
            wrapText: true
          });
        }

        sheet.range(dataInfo.theadRange).style({
          fill: "1976D2",
          bold: true,
          horizontalAlignment: "center",
          fontColor: "ffffff",
        });

        // if (dataInfo.tFirstColumnRange) {
        //   sheet.range(dataInfo.tFirstColumnRange).style({
        //     bold: true,
        //   });
        // }

        if (dataInfo.tLastColumnRange) {
          sheet.range(dataInfo.tLastColumnRange).style({
            bold: true,
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Button variant="contained" onClick={() => {createDownLoadData();}}>
      Export to XLSX
    </Button>
  );
};

export default ExcelExportHelper;
