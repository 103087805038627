import { Container, Typography } from "@mui/material";

function ErrorNotification(props) {
  return (
    <Container maxWidth="xl">
      <Typography variant="h6" style={props.style}>
        {`Something's wrong: `}
        <span>{`${props.code && `[${props.code}] `}${props.message && `${props.message}`}`}</span>
      </Typography>
    </Container>
  );
}

export default ErrorNotification;
