import React, { useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Container, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import * as Date from "../helpers/datePrototypes";
import * as yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box } from "@mui/system";

const schema = yup.object().shape({
  type: yup.string().required(),
  companyName: yup.string().required(),
  scannerKey: yup.string().min(32).required(),
  products: yup.array().of(
    yup.object().shape({
      creatorId: yup.string(),
      description: yup.string(),
      id: yup.string(),
      name: yup.string(),
      creationTime: yup.string(),
      archival: yup.boolean(),
    })
  ).min(1),
  scannerModel: yup.string().required(),
  scannerSerialNumber: yup.string().required(),
  validTill: yup
    .date()
    .required()
    .nullable()
    .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
    .min(
      new Date(),
      `validTill field must be later than ${new Date().toLocaleDateString(
        "ru-RU"
      )}`
    ),
});

function CreateLicenseForm(props) {
  const {
    trigger,
    setValue,
    getValues,
    resetField,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const scannerKeyInputRef = useRef();
  const [disable, setDisable] = React.useState(!props.isScannerKeyRequered);

  useEffect(async () => {
    setDisable(!props.isScannerKeyRequered);
    setValue("scannerKey", props.scannerKey ? props.scannerKey : "");
    scannerKeyInputRef.current.value = props.scannerKey;
    if(props.scannerKey){
      trigger("scannerKey");
    }
  }, [props.scannerKey, props.isScannerKeyRequered]);

  const handleEditModeClick = () => {
    setDisable(!disable);
  };

  return (
    <Container maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Add a new license
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  freeSolo
                  options={props.companies.map((option) => option.name)}
                  getOptionLabel={(option) => option}
                  onChange={(_, name) => {
                    field.onChange(name);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Company Name"
                      error={Boolean(errors.companyName?.message)}
                      helperText={errors.companyName?.message}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={(param) => (
                <Autocomplete
                  {...param}
                  disabled={props.isLoading}
                  options={props.scannerTypes}
                  getOptionLabel={(option) => option}
                  onChange={(_, type) => {
                    param.field.onChange(type);
                    props.onTypeChange(getValues());
                    resetField("scannerModel");
                    resetField("scannerSerialNumber");
                  }}
                  renderInput={(field) => (
                    <TextField
                      {...field}
                      label="Scanner Type"
                      variant="outlined"
                      error={Boolean(errors.type?.message)}
                      helperText={errors.type?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              key={props.models}
              name="scannerModel"
              control={control}
              render={(param) => (
                <Autocomplete
                  {...param}
                  key={props.models}
                  disabled={props.isLoading}
                  options={props.models}
                  getOptionLabel={(option) => option}
                  onChange={(_, model) => {
                    param.field.onChange(model);
                    props.onModelChange(getValues());
                    resetField("scannerSerialNumber");
                  }}
                  renderInput={(field) => (
                    <TextField
                      {...field}
                      label="Scanner Model"
                      variant="outlined"
                      error={Boolean(errors.scannerModel?.message)}
                      helperText={errors.scannerModel?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Controller
              key={props.models}
              name="scannerSerialNumber"
              control={control}
              defaultValue=""
              render={(param) => (
                <Autocomplete
                  {...param}
                  key={props.scannerNumbers}
                  disabled={props.isLoading}
                  options={props.scannerNumbers}
                  getOptionLabel={(option) => option}
                  onChange={(_, scannerNumber) => {
                    param.field.onChange(scannerNumber);
                    props.onSerialNumberChange(getValues());
                    resetField("scannerKey");
                  }}
                  renderInput={(field) => (
                    <TextField
                      {...field}
                      label="Serial Number #"
                      variant="outlined"
                      error={Boolean(errors.scannerSerialNumber?.message)}
                      helperText={errors.scannerSerialNumber?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {(props.isScannerKeyRequered || props.scannerKey) && (
            <Grid item xs={12}>
              <Controller
                key={getValues().scannerSerialNumber}
                name="scannerKey"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputRef={scannerKeyInputRef}
                    disabled={disable}
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    label="Scanner Key"
                    error={Boolean(errors.scannerKey?.message)}
                    helperText={errors.scannerKey?.message}
                    InputProps={!props.isScannerKeyRequered && {
                      endAdornment: (
                        <Box sx={{ display: "flex" }}>
                          <Divider orientation="vertical" flexItem />
                          <IconButton
                            onClick={handleEditModeClick}
                            edge="end"
                            style={{ margin: "0px -8px 0px 6px" }}
                          >
                            {disable ? <EditIcon /> : <DoneAllIcon color="primary" />}
                          </IconButton>
                        </Box>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl
              component="fieldset"
              variant="standard"
              error={errors?.products}
            >
              <FormLabel component="legend" style={{ marginBottom: "10px" }}>
                Choose products for the license
              </FormLabel>
              <FormGroup row>
                <Controller
                  defaultValue={[]}
                  name="products"
                  control={control}
                  render={({ field }) =>
                    props.products.map((item) => (
                      <Grid item xs={12} key={item.id}>
                        <FormControlLabel
                          {...field}
                          label={item.name}
                          control={
                            <Checkbox
                              disabled={props.isLoading}
                              style={{
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                              onChange={() => {
                                if (!field.value.includes(item)) {
                                  field.onChange([...field.value, item]);
                                  return;
                                }
                                const newProducts = field.value.filter(
                                  (product) => product !== item
                                );
                                field.onChange(newProducts);
                              }}
                            />
                          }
                        />
                      </Grid>
                    ))
                  }
                />
              </FormGroup>
              <FormHelperText>{errors?.products?.message}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <Controller
              name="validTill"
              control={control}
              defaultValue={new Date().addYears(1)}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    {...field}
                    minDate={new Date().addDays(1)}
                    label="Valid till"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.validTill?.message)}
                        helperText={errors.validTill?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.isLoading}
              >
                Generate License
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default CreateLicenseForm;
