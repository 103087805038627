import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const productAPI = {
  create,
  getAll,
  update,
  get,
};

async function create(data) {
  try {
    return await $api.post("/product/create", data);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function getAll() {
  try {
    return await $api.get(`/Product`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function update(data) {
  try {
    return await $api.put(`/product/updateProduct`, data);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function get(id) {
  try {
    return await $api.get(`/product/getById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
