import { Container, Grid, List, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CompanyForm from "../components/CompanyForm";
import ErrorNotification from "../components/ErrorNotification";
import LicenseCard from "../components/LicenseCard";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../hooks/useAuth";
import { companyAPI } from "../services/api/companyAPI";
import { licenseAPI } from "../services/api/licenseAPI";
import { withAuth } from "../hocs/withAuth";
import { useSelector } from "react-redux";

function CompanyInfoPage(props) {
  const { permissions } = useSelector((state) => state.permissions);
  const { id } = useParams();
  const { isAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [preloadData, setPreloadData] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const navigate = useNavigate();

  useEffect(async () => {
    loadCompany();
  }, []);

  const loadCompany = async () => {
    setIsLoading(true);
    let response = await companyAPI.get(id);
    if (response.status === 200) {
      const preloads = getObjectWithoutNullProps(response.data);
      setPreloadData(preloads);
      await getLicenses(response.data.id);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const getObjectWithoutNullProps = (obj) => {
    const notNullData = new Object();
    Object.entries(obj)
      .filter(([name, value]) => value !== null)
      .map(([name, value]) => ({name, value}))
      .forEach((el) => notNullData[el.name] = el.value);
    return notNullData;
  }

  const getLicenses = async (companyId) => {
    setIsLoading(true);
    let response = await licenseAPI.getByCompanyId(companyId);
    if (response.status === 200) {
      setLicenses(response.data);
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const onDownload = async (id) => {
    setIsLoading(true);
    let response = await licenseAPI.download(id);
    if (response.status === 200) {
      const contentDisposition = response.headers["content-disposition"];
      const content = contentDisposition.split(";");
      const index = content.findIndex((el) => el.includes(" filename="));
      if (index !== -1) {
        const filename = content[index].replace(" filename=", "");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}`);
        document.body.appendChild(link);
        link.click();
      }
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    let response = await companyAPI.update(data);
    if (response.status === 200) {
      navigate("/");
    } else {
      let message = response.data.message;
      setError({
        code: response.status,
        message: message ? message : response.statusText,
      });
    }
    setIsLoading(false);
  };

  if(!isAuth){
    return <Navigate to="/login" />
  }

  return (
    <Container maxWidth="xl">
      <title>Company info</title>
      <h1>Company info</h1>
      <Grid container spacing={10}>
        <CompanyForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          preloads={preloadData}
        />
        {error && (
          <ErrorNotification
            code={error?.code}
            message={error?.message}
            style={{
              margin: "60px 0 20px 0",
              color: "#EB3941",
              textAlign: "center",
            }}
          />
        )}
        <Grid item xs={6}>
          <Typography variant="h6" margin="40px 0 20px 0">
            Licenses:
          </Typography>
          <div style={{ overflow: "auto" }}>
            <List>
              <Grid container spacing={2} className={"grid-column"}>
                {licenses &&
                  licenses.map((i, index) => (
                    <LicenseCard
                      key={index}
                      {...i}
                      onDownload={onDownload}
                      canDownload={permissions?.downloadLicense}
                    />
                  ))}
              </Grid>
            </List>
          </div>
        </Grid>
      </Grid>
      {error && (
        <ErrorNotification
          code={error.code}
          message={error.message}
          style={{ margin: "40px 0 0 0", color: "#EB3941" }}
        />
      )}
      {isLoading && <Loader />}
    </Container>
  );
}

export default withAuth(CompanyInfoPage);
