import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./slices/usersSlise";
import licenseReducer from "./slices/licensesSlise";
import permissionReducer from "./slices/permissionsSlise";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    licenses: licenseReducer,
    permissions: permissionReducer
  },
});
