import $api from "../axios";
import { handleResponse } from "../../helpers/httpHelpers";

export const companyAPI = {
  getAll,
  get,
  update,
};

async function getAll() {
  try {
    return await $api.get(`/Company`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function get(id) {
  try {
    return await $api.get(`/company/getById?id=${id}`);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}

async function update(info) {
  try {
    return await $api.put(`/company/update`, info);
  } catch (err) {
    return Promise.resolve(handleResponse(err.response));
  }
}
