import { Container } from "@mui/material";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import ChangePasswordForm from "../components/ChangePasswordForm";
import { userAPI } from "../services/api/userAPI";
import { useAuth } from "../hooks/useAuth";
import ProfileInfo from "../components/ProfileInfo";
import Loader from "../components/Loader/Loader";

function ProfilePage() {
  const { isAuth, user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [passwordChangeError, setPasswordChangeError] = useState(null);

  const onChangePassword = async (data) => {
    setIsLoading(true);
    setIsPasswordUpdated(false);
    setPasswordChangeError(null);
    let response = await userAPI.changePassword(
      data.currentPassword,
      data.newPassword
    );
    if (response.status === 200) {
      localStorage.setItem("user", JSON.stringify(response.data));
      setIsPasswordUpdated(true);
    } else {
      let message = response.data.message;
      setPasswordChangeError({
        code: response.status,
        message: message ? message : response.statusText,
      });
      console.log(message);
    }
    setIsLoading(false);
  };

  if(!isAuth){
    return <Navigate to="/login" />
  }

  return (
    <Container maxWidth="sm">
      <title>Profile</title>
      <ProfileInfo {...user}/>
      <ChangePasswordForm
        login={user.email}
        isLoading={isLoading}
        onSubmit={onChangePassword}
        responseError={passwordChangeError}
        isUpdated={isPasswordUpdated}
      />
      {isLoading && <Loader />}
    </Container>
  );
}

export default ProfilePage;
