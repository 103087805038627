import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const usersSlise = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    toggleActivate(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].isActive = !state.users[userIndex].isActive;
    },
    toggleDownloadLicense(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.downloadLicense =
        !state.users[userIndex].permissions.downloadLicense;
    },
    toggleAddLicense(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.addLicense =
        !state.users[userIndex].permissions.addLicense;
    },
    toggleSeeAllCustomers(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.seeAllCustomers =
        !state.users[userIndex].permissions.seeAllCustomers;
    },
    toggleEditUser(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.editUserInfo =
        !state.users[userIndex].permissions.editUserInfo;
    },
    toggleEditCompany(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.editCompanyInfo =
        !state.users[userIndex].permissions.editCompanyInfo;
    },
    toggleEditProduct(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].permissions.editProduct =
        !state.users[userIndex].permissions.editProduct;
    },
    changeName(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].name = action.payload.data;
    },
    changeSurname(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].surname = action.payload.data;
    },
    changeEmail(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].email = action.payload.data;
    },
    toggleEditMode(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex].isEdited = !state.users[userIndex].isEdited;
    },
    updateUser(state, action) {
      let userIndex = state.users.findIndex((obj) => obj.id === action.payload.id);
      if (userIndex === -1) return;
      state.users[userIndex] = { ...action.payload.data };
    },
  },
});

export const {
  toggleActivate,
  toggleDownloadLicense,
  toggleAddLicense,
  setUsers,
  toggleEditUser,
  toggleEditCompany,
  toggleSeeAllCustomers,
  toggleEditProduct,
  changeName,
  changeSurname,
  changeEmail,
  toggleEditMode,
  updateUser,
} = usersSlise.actions;

export default usersSlise.reducer;
