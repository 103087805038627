import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ExcelExportHelper from "./ExcelExportHelper";

const ActionBox = ({ margin, permissions, data}) => {
    return (
      <div style={{margin: margin}}>
        <Grid container spacing={2}>
          {permissions?.addLicense && (
            <Grid item>
            <Button variant="contained" component={Link} to="/create-license">
              Add license
            </Button>
          </Grid>
          )}
          <Grid item>
            <ExcelExportHelper data={data} />
          </Grid>
        </Grid>
      </div>
    );
  };

export default ActionBox;